body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: black;
  color: #f0f0f0; /* Off-white color */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('./assets/Logo.png') no-repeat center center fixed;
  background-size: contain;
  opacity: 0.1;
  z-index: -1;
}

.banner {
  width: 100%;
  height: auto;
  max-height: 350px; /* Adjusted height to 350px */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner img {
  width: 100%;
  height: auto;
  object-fit: cover; /* Ensure the image covers the area without being cut off */
}

main {
  width: 100%;
  max-width: 800px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

section {
  width: 100%;
  padding: 20px;
  text-align: center;
}

.services ul {
  list-style-type: none;
  padding: 0;
}

.services ul li {
  margin: 10px 0;
}

.truck-image {
  width: 50px;
  height: auto;
  margin-left: 10px;
}


.drinks-ad img {
  width: 100%;
  height: auto;
  margin-top: 20px;
}

.gallery {
  width: 100%;
  padding: 20px;
  text-align: center;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
}

.gallery-item {
  cursor: pointer;
  position: relative;
  width: 100%;
  padding-top: 75%; /* 4:3 Aspect Ratio */
  overflow: hidden;
}

.gallery-item img,
.gallery-item video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  transition: transform 0.2s;
}

.gallery-item:hover img,
.gallery-item:hover video {
  transform: scale(1.05);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 90%;
  max-height: 90%;
}

.modal-content img,
.modal-content video {
  width: 100%;
  height: auto;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}


footer {
  width: 100%;
  max-width: 800px;
  padding: 20px;
  text-align: center;
  background-color: #000;
}

footer div {
  display: flex;
  justify-content: center;
  gap: 10px;
}

footer img {
  width: 40px;
  height: 40px;
}

@media (max-width: 600px) {
  footer div {
    flex-direction: column;
    gap: 5px;
  }

  footer img {
    width: 30px;
    height: 30px;
  }
}
